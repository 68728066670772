import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useEffect, useMemo } from "react";
import type { VirtuosoHandle } from "react-virtuoso";
import { Virtuoso } from "react-virtuoso";
import type { ChatMessage } from "src/ui/components/Chat/ChatBloc";
import ChatMessageItem from "src/ui/components/Chat/ChatMessageItem";

const PlaceholderMessage = styled.div`
  height: 1.5rem;

  div[data-index]:first-of-type &.top {
    height: calc(6rem + var(--safe-area-top, 0px));
  }
  div[data-index]:first-of-type &.top-small {
    height: calc(1.5rem + var(--safe-area-top, 0px));
  }
`;

const VirtualizedMessageList: FC<{
  messages: ChatMessage[];
  onStartReached?: () => void;
  onEndReached?: () => void;
  onListInitialized?: (list: VirtuosoHandle | null) => void;
  onBottomStateChange?: (atBottom: boolean) => void;
  firstItemIndex?: number;
  moreHistoryAvailable?: boolean;
  markMessageAsRead?: (message: ChatMessage) => void;
}> = ({
  messages,
  onStartReached,
  onEndReached,
  firstItemIndex,
  onListInitialized,
  onBottomStateChange,
  moreHistoryAvailable,
  markMessageAsRead
}) => {
  const virtuosoRef = React.useRef<VirtuosoHandle>(null);
  const allowCallbacks = React.useRef(false);

  const overscan = useMemo<number>(() => {
    return 2000;
  }, []);

  useEffect(() => {
    onListInitialized?.(virtuosoRef.current);

    if (virtuosoRef.current) {
      virtuosoRef.current.scrollToIndex({
        index: messages.length + 10,
        align: "end"
      });
    }
  }, [virtuosoRef]);

  useEffect(() => {
    setTimeout(() => {
      allowCallbacks.current = true;
    }, 500);
  }, []);

  const lastMessageId = messages[messages.length - 1]?.id;

  // add first and last item for placeholder
  const placeholderTop = {
    placeholder: moreHistoryAvailable ? "top" : "top-small"
  } as ChatMessage;
  const placeholderBottom = { placeholder: "bottom" } as ChatMessage;

  return (
    <Virtuoso
      itemSize={(el) => el.clientHeight}
      className="virtuoso"
      overscan={overscan}
      ref={virtuosoRef}
      firstItemIndex={firstItemIndex}
      atBottomStateChange={(e) =>
        allowCallbacks.current ? onBottomStateChange?.(e) : null
      }
      atTopStateChange={(atTop) => {
        if (atTop && allowCallbacks.current) {
          onStartReached?.();
        }
      }}
      endReached={() => (allowCallbacks.current ? onEndReached?.() : null)}
      followOutput={(isAtBottom: boolean) => {
        if (isAtBottom) {
          return "auto";
        } else {
          return false;
        }
      }}
      data={[placeholderTop, ...messages, placeholderBottom]}
      itemContent={(_, message) => (
        <>
          {message.placeholder && (
            <PlaceholderMessage className={message.placeholder} />
          )}

          {!message.placeholder && (
            <ChatMessageItem
              message={message}
              isLastMessage={lastMessageId === message.id}
              markMessageAsRead={markMessageAsRead}
            />
          )}
        </>
      )}
    />
  );
};

export default VirtualizedMessageList;
